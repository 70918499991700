import { mapMetaTypeCodes, reformatTypeCode } from '@raywhite/data-utils/lib/data/meta/helpers'
import { withStaticProps } from '../../../components/hocs/providers'
import ListingCardList from '../../../components/presentational/ListingCardList.jsx'

/**
 * Create a wrapped component with the listings based on the recentStatusCode
 * and recentSort of the site focus
 *
 * @param {Object} meta The site listings metadata loaded via unifiedClient.
 * @param {String} siteFocus The chosen focus for the site.
 *
 * @return {Object}
 */
export const buildRecentTransactions = (meta, siteConfig, withSearch) => {
  const {
    recentStatusCode,
    recentSort,
    searchParams: { typeCode },
  } = siteConfig
  const typeEntries = Object.entries(meta).filter(entry => entry[0] !== 'total')
  // eslint-disable-next-line no-unused-vars
  const hasRecentTypes = typeEntries.reduce((result, [code, data]) => {
    if (result) return result

    // Ensure that type matches
    if (typeCode && typeCode.indexOf(code) === -1) return false

    return Array.isArray(recentStatusCode)
      ? !!recentStatusCode.filter(item => !!data.statusCode[item]).length
      : !!data.statusCode[recentStatusCode]
  }, false)

  if (hasRecentTypes) {
    const params = {
      statusCode: recentStatusCode,
      typeCode,
      size: 6,
      sort: recentSort,
    }

    return {
      params,
      component: withSearch(params)(ListingCardList),
    }
  }

  return {
    params: {},
    component: null,
  }
}

const paths = {
  sale: {
    default: '/properties/for-sale',
    COMSAL: '/properties/commercial-for-sale',
    SAL: '/properties/residential-for-sale',
  },
  rent: {
    default: '/properties/for-rent',
    COMLSE: '/properties/commercial-for-lease',
    REN: '/properties/residential-for-rent',
  },
}

const getSalePath = keys => paths.sale[keys.join('')] || paths.sale.default
const getRentPath = keys => paths.rent[keys.join('')] || paths.rent.default

/**
 * Determine the "what's on" categories that can be shown for this site, based
 * on the chosen site focus and the types of listings available to the site.
 *
 * @param {Object} meta The site listings metadata loaded via unifiedClient.
 * @param {String} string The date to show events from, e.g. to exclude inspections in the past.
 * @param {Object} siteConfig The site's config based on the site's focus
 * @params {Function} withSearch HOC to wrap ListingCardList for category
 *
 * @return {Array} An array of categories to pass through to WhatsOnBox.
 */
export const buildWhatsOnCategories = (meta, eventsSince, siteConfig, withSearch) => {
  const categories = []
  const {
    showInvestments = false,
    searchParams,
    searchParams: {
      typeCode: _typeCode,
      subTypeCode,
    } = {},
  } = siteConfig

  // Figure out the permitted types
  const typeCode = mapMetaTypeCodes(_typeCode, subTypeCode)

  // Drop any unsupported types from the type entries
  const typeEntries = Object.entries(meta)
    .filter(([code, { statusCode }]) => {
      // 'total' is not a type
      if (code === 'total') return false

      // Ignore unsupported types
      if (!typeCode[code]) return false

      // Ignore those without current data
      if (!statusCode.CUR) return false

      return true
    })
    // Clean up data for use below
    .map(([code, data]) => [reformatTypeCode(code), data.statusCode.CUR, code])

  const currentTypes = typeEntries.map(([code]) => code)
  const currentTypeKeys = typeEntries.map(([,, typeKey]) => typeKey)
    .filter(typeKey => !typeKey.match(/BOTH$/))

  if (currentTypes.length) {
    const links = []

    const rentals = currentTypeKeys.filter(typeKey => typeKey === 'REN' || typeKey === 'COMLSE')
    const nonRentals = currentTypeKeys.filter(typeKey => typeKey !== 'REN' && typeKey !== 'COMLSE')
    if (nonRentals.length) {
      links.push({
        url: getSalePath(nonRentals),
        label: 'See all for sale',
      })
    }
    if (rentals.length) {
      links.push({
        url: getRentPath(rentals),
        label: 'See all rentals',
      })
    }
    const params = {
      ...searchParams,
      typeCode: [... new Set(currentTypes)],
      size: 6,
      sort: 'creationTime desc',
    }
    categories.push({
      label: 'New',
      params,
      component: withSearch(params)(ListingCardList),
      links,
    })
  }

  const inspectionTypes = typeEntries
    .filter(([, data]) => data.inspections.total)
    .map(([code]) => code)
  if (inspectionTypes.length) {
    const links = []

    const nonRentals = inspectionTypes.filter(code => code !== 'REN')
    if (nonRentals.length) {
      links.push({
        // Special handling for offices with only resi
        url: nonRentals.length === 1 && nonRentals.indexOf('SAL') !== -1
          ? '/properties/residential-for-sale/open-for-inspection'
          : '/properties/for-sale/open-for-inspection',
        label: 'All for sale inspections',
      })
    }
    if (inspectionTypes.indexOf('REN') !== -1) {
      links.push({
        url: '/properties/residential-for-rent/open-for-inspection',
        label: 'All rental inspections',
      })
    }

    const params = {
      ...searchParams,
      inspectionDate: {
        gte: eventsSince,
      },
      typeCode: inspectionTypes,
      size: 6,
      sort: ['inspectionDate asc', 'id asc'],
    }
    categories.push({
      label: 'Open Soon',
      params,
      component: withSearch(params)(ListingCardList),
      links,
    })
  }

  if (showInvestments) {
    const investmentTypes = typeEntries
      .filter(([, data]) => data.investments.total)
      .map(([code]) => code)
    if (investmentTypes.length) {
      const params = {
        ...searchParams,
        tenantedPercent: {
          gte: 1,
        },
        typeCode: [...new Set(inspectionTypes)],
        size: 6,
        sort: ['creationTime desc', 'id desc'],
      }

      if (siteConfig.theme === 'commercial') {
        params.presort = ['elite']
      }

      categories.push({
        label: 'Investments',
        params,
        component: withSearch(params)(ListingCardList),
        links: [{
          url: '/properties/investment',
          label: 'All investments',
        }],
      })
    }
  }

  const auctionTypes = typeEntries
    .filter(([, data]) => data.auctions.total)
    .map(([code]) => code)
  if (auctionTypes.length) {
    const params = {
      ...searchParams,
      auctionDate: {
        gte: eventsSince,
      },
      typeCode: [... new Set(auctionTypes)],
      size: 6,
      sort: ['auctionDate asc', 'id desc'],
    }
    categories.push({
      label: 'Upcoming Auctions',
      params,
      component: withSearch(params)(withStaticProps('ListingCardList', { cardOptions: { badgeField: 'auction' } })(ListingCardList)),
      links: [
        { url: '/properties/for-sale/auctions', label: 'See all auctions' },
      ],
    })
  }

  return categories
}
